* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

:root {
  /* making 1rem = 10px */
  font-size: 62.5%;

  /* all the brand colors */
  --white_nav: rgba(255,255,255, 0.54);
  --primary_one: #07484A;
  --primary_two: #70908B;
  --secondary_one: #CAF3E5;
  --secondary_two: #E0EFF6;
  --secondary_three: #EEEBFF;
  --secondary_four: #FFF4E7;
  --secondary_five: #FDFBF8;
  --secondary_six: #E0F6F1;
  --secondary_seven: #F9D9DA;

  /* padding variables */
  --padding_left: 150px;
  --padding_right: 150px;

  --padding_top: 100px;
  --padding_bottom: 100px;
  --nav-height: 155.65px;
}

body {
  background-color: var(--secondary_five);
  /* width: 100vw; */
}

/* classes for different font sizes */
.font_18 {
  font-size: 1.8rem;
}

.font_20 {
  font-size: 2rem;
}

.font_24 {
  font-size: 2.4rem;
}

.font_26 {
  font-size: 2.6rem;
}

.font_28 {
  font-size: 2.8rem;
}

.font_36 {
  font-size: 3.6rem;
}

.font_section_heading {
  font-size: 6.4rem;
}


/* font weight classes */
.font_bold {
  font-weight: bold;
}

/* font color classes */
.font_white {
  color: white;
}

.font_primary_one {
  color: var(--primary_one);
}

.font_primary_two {
  color: var(--primary_two);
}

.font_color_primary {
  color: var(--primary_one);
}

/* playfair font reusable class */
.font_playfair {
  font-family: 'Playfair Display', serif;
}

/* open sans font reusable class */
.font_open_sans {
  font-family: 'Open Sans', sans-serif;
}


.nav_padding {
  height: var(--nav-height);
  max-width: 100vw;
}






/* responsive desing styling */
@media only screen and (max-width: 1250px) {
  :root {

    /* font size */
    font-size: 60%;;

    /* padding variables */
    --padding_left: 120px;
    --padding_right: 120px;
  }
}

@media only screen and (max-width: 1050px) {
  :root {

    /* font size */
    font-size: 58%;;

    /* padding variables */
    --padding_left: 40px;
    --padding_right: 40px;
  }
}

@media only screen and (max-width: 890px) {
  :root {
    --nav-height: 100px;
  }
}

@media only screen and (max-width: 600px) {
  :root {

    /* font size */
    font-size: 48%;

  }
}

@media only screen and (max-width: 400px) {
  :root {

    /* font size */
    font-size: 40%;

  }
}