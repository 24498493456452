.productCard {
    /* width: 300px; */
    width: 100%;
    /* height: 400px; */
    background-color: var(--secondary_one);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

/* background color id's */

#color_one {
    background-color: var(--secondary_one);
}

#color_two {
    background-color: var(--secondary_two);
}

#color_three {
    background-color: var(--secondary_three);
}

#color_four {
    background-color: var(--secondary_four);
}


.productCard__imageContainer {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.productCard__image {
    width: 80%;
    height: 80%;
    aspect-ratio: 16 / 12;
    object-fit: scale-down;
    /* background-color: red; */
}

.productCard__info {
    width: 80%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: start;
    color: var(--primary_two);
}

.productCard__info--content {
    margin-bottom: 7px;
}