.ownPackage {
    max-width: 100vw;
    height: 350px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--padding_top);
    margin-bottom: var(--padding_top);
    overflow: hidden;
}

.ownPackage__info--container {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: start;
    border: none;
    background-color: var(--secondary_five);
    z-index: 5;
}

.ownPackage__info {
    width: 98%;
    height: 100%;
    padding-left: 7rem;
    padding-top: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    background-color: var(--primary_two);
    border-radius: 0 8px 8px 0;
    z-index: 5;
}


/* navigation button styling */
.ownPackage--navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ownPackage--navigaion--previous {
    width: 40px;
    height: 40px;
    background-color: var(--secondary_two);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.ownPackage--navigaion--next {
    width: 40px;
    height: 40px;
    background-color: var(--secondary_seven);
    border-radius: 50%;
    cursor: pointer;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

/* carousel general styling */
.ownPackage__carousel {
    --slider-index: 0;
    --items-per-screen: 4;
    width: 70%;
    /* width: calc(70% - 2* 2rem); */
    height: 100%;
   transform: translateX(calc(var(--slider-index) * -100%));
   transition: transform 250ms ease-in-out;
    /* padding-left: 2rem; */
    display: flex;
    align-items: center;
}

.ownPackage--cardContainer {
    width: 270px;
    min-width: calc(100% / var(--items-per-screen));
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left: 1rem; */
    margin-right: 1rem;
    transition: transform 0.2s ease;
}

/* card styling */
.ownPackageCard {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.5s ease;
}

.ownPackageCardImage {
    width: 100%;
    height: 100%;
    /* position: absolute; */
    object-position: contain;
    /* aspect-ratio: 16 / 6; */
    border-radius: 8px;
    /* top: 0;
    bottom: 0;
    left: 0;
    right: 0; */
}

.ownPackageCard--button {
    width: 70%;
    border-radius: 8px;
    position: absolute;
    bottom: 10%;
    left: 50%;
    padding: 10px 20px;
    translate: -50% 0%;
    cursor: pointer;
    border: none;
    background-color: #fff;
    display: none;
    transition: display 0.5s ease;
}

.ownPackageCard:hover > .ownPackageCard--button {
    display: block;
}

/* .ownPackage--cardContainer:hover {
    transform: scaleY(1.05)
} */



/* scrollbar styles */
.ownPackage__scrollBar {
    width: 70%;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.ownPackage__scrollBar--item {
    width: 33.3%;
    height: 2px;
    background-color: var(--primary_one);
}

.ownPackage--active {
    height: 3px;
    background-color: white;
}


/* responsive design */
@media only screen and (max-width: 1000px) {
    .ownPackage__carousel {
        --items-per-screen: 3;
    }
}

@media only screen and (max-width: 690px) {
    .ownPackage__carousel {
        --items-per-screen: 2;
    }
    .ownPackage__info {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

@media only screen and (max-width: 490px) {
    .ownPackage__carousel {
        --items-per-screen: 1;
        width: 60%;
    }
    .ownPackage__info--container {
        width: 40%;
    }
}