.homeBenifits {
    max-width: 100vw;
    background-color: var(--secondary_two);
    margin-bottom: var(--padding_bottom);
    margin-top: var(--padding_top);
    padding-top: 40px;
    padding-bottom: 40px;
}

.homeBenifits__heading {
    width: 100%;
    padding-left: var(--padding_left);
    padding-right: var(--padding_right);
    text-align: center;
}

.homeBenifits__content {
    width: 100%;
    padding-left: var(--padding_left);
    padding-right: var(--padding_right);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.homeBenifits__content--data {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 60px;
}

.homeBenifits__content--data--image_container {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: var(--secondary_one); */
    padding: 15px 15px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.homeBenifits__content--data--image {
    width: 80%;
}

.homeBenifits--container-1 {
    background-color: var(--secondary_three);
}

.homeBenifits--container-2 {
    background-color: var(--secondary_four);
}

.homeBenifits--container-3 {
    background-color: var(--secondary_one);
}

/* responsive design styling */
@media only screen and (max-width: 440px) {
    .homeBenifits {
        padding-bottom: 20px;
    } 
    .homeBenifits__content {
        flex-direction: column;
        justify-content: center;
    }
    .homeBenifits__content--data {
        width: 80%;
        margin-bottom: 0px;
    }
}