.footer {
    max-width: 100vw;
    /* height: 200px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer__newsletter {
    max-width: 100%;
    background-color: var(--secondary_six);
    display: flex;
}

.footer__newsletter--image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.footer__newsletter--left {
    width: 50%;    
}

.footer__newsletter--right {
    width: 50%;
    padding-left: 150px;
    padding-right: 40px;
    padding-top: 60px;
    padding-bottom: 30px;
}

.footer__newsletter--image {
    width: 100%;
}

.footer__newsletteer--right--heading {
    font-size: 6rem;
    color: var(--primary_one);
}

.footer__newsletteer--right--text {
    color: var(--primary_one);
    margin-top: 20px;
}

.footer__newsletter--input {
    width: 60%;
    padding: 10px 10px;
    border-radius: 8px;
    outline: none;
    border: none;
    background-color: transparent;
    margin-top: 30px;
    font-size: 2.2rem;
    color: rgb(172, 172, 172);
}

.footer__newsletter--input::placeholder  {
    color: #C4C4C4;
}

.footer__newsletter--button {
    font-size: 2.4rem;
    color: white;
    padding: 14px 24px;
    display: block;
    border-radius: 8px;
    background-color: var(--primary_two);
    border: none;
    margin-top: 30px;
    cursor: pointer;
}



/* footer map section */

.footer__map {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 32px;
    /* padding-left: var(--padding--left);
    padding-right: var(--padding--right); */
    /* padding-right: 40px; */
    background-color: #F3F6F5;
}

.footer__map--content {
    padding-left: var(--padding_left);
    padding-right: var(--padding_right);
    display: flex;
    margin-bottom: 60px;
}

.footer__map--left {
    width: 30%;
}

.footer__map--heading {
    display: flex;
    align-items: start;
    flex-direction: column;
}

.footer__map--heading--logo {
    width: 95px;
    cursor: pointer;
    margin-bottom: 30px;
}

/* .footer__map--heading--text--address {

} */

.footer__map--heading--text {
    /* letter-spacing: 1px; */
    /* line-height: 25px; */
    /* cursor: pointer; */
    padding-right: 2rem;
    margin-top: 1rem;
}


/* footer links styles */
.footer__map--links {
    width: 75%;
    /* background-color: grey; */
    display: flex;
    justify-content: space-between;
    /* background-color: red; */
    align-items: start;
}

/* .footer__map--link {
    width: 13%;
} */

.footer__map--link-heading {
    margin-bottom: 30px;
}

.footer__map--link-text {
    margin-bottom: 20px;
    cursor: pointer;
}

/* copywright section styles */
.footer__map--copyright {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: var(--padding_left);
    padding-right: var(--padding_right);
    padding-top: 50px;
    /* padding-bottom: 50px; */
}

.footer__map--copyright--text {
    color: var(--primary_one);
}

.footer__map--socials {
    /* width: 10%; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#footer__map--socials--icons {
    font-size: 2.8rem;
    margin-left: 36px;
    color: var(--primary_one);
}

/* responsive desing */
@media only screen and (max-width: 1450px) {
    .footer__newsletter--right {
        width: 50%;
        padding-left: 50px;
        padding-top: 60px;
    }
}


@media only screen and (max-width: 840px) {

    .footer__map--content {
        flex-direction: column;
    }
    .footer__map--left {
        margin-bottom: 50px;
        width: 90%;
    }
    .footer__map--heading--text {
        padding-right: 3rem;
    }
}

@media only screen and (max-width: 740px) {
    .footer__map--links {
        width: 100%;
        flex-wrap: wrap;
    }
    .footer__map--copyright {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    .footer__map--copyright--text {
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }
    .footer__map--socials {
        width: 60%;
        
        justify-content: space-between;
        align-items: center;
    }
    #footer__map--socials--icons {
        margin-left: 0px;
    }
}


@media only screen and (max-width: 700px) {
    .footer__newsletter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .footer__newsletter--left {
        width: 100%;
    }
    .footer__newsletter--right {
        width: 100%;
        padding-left: var(--padding_left);
        padding-right: var(--padding_right);
    }
    .footer__map--link {
        width: 50%;
        margin-bottom: 20px;
    } 

    .footer__map--copyright--text {
        width: 100%;
        /* padding-right: var(--padding_right); */
        text-align: center;
    }
}