.homeTestimonial {
    max-width: 100vw;
    margin-bottom: var(--padding_bottom);
    margin-top: var(--padding_top);
}

.homeTestimonial__heading {
    width: 100%;
    /* font-size: 5rem; */
    padding-left: var(--padding_left);
    padding-right: var(--padding_right);
    text-align: center;
}

.homeTestimonial__subheading {
    width: 100%;
    text-align: center;
    padding-left: var(--padding_left);
    padding-right: var(--padding_right);
    margin-top: 1.8rem;
}

.homeTestimonial__testimonialContent {
    display: flex;
    margin-top: 30px;
}

/* testimonial styling */

.homeTestimonial__content {
    width: 100%;
    padding-left: var(--padding_left);
    padding-right: var(--padding_right);
}

.homeTestimonial__content--testimonial {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.homeTestimonial__content--testimonial--left {
    width: 40%;
    height: 350px;
    background-color: rgb(224, 224, 224);
    border-radius: 8px;
    position: relative;
}

.homeTestimonial__content--testimonial--left--image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.homeTestimonial__content--testimonial--left--quote {
    width: 100px;
    position: absolute;
    top: -10%;
    left: -10%;
}

.homeTestimonial__content--testimonial--right {
    width: 60%;
    height: 400px;
    padding-left: 50px;
    padding-top: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.homeTestimonial_text {
    font-size: 2.2rem;
}

.homeTestimonial_name {
    margin-top: 20px;
    font-size: 1.8rem;
}

.homeTestimonial_position {
    margin-top: 10px;
    font-size: 1.8rem;
}

/* navigation button styling */
.homeTestimonial__content--navigation {
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
}

.homeTestimonial__content--navigation--previous {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--secondary_two);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.homeTestimonial__content--navigation--next {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #F9D9DA;
    cursor: pointer;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}


/* responsive desings */
@media only screen and (max-width: 850px) {
    .homeTestimonial__content--testimonial--left {
        height: 300px;
    }
}

@media only screen and (max-width: 660px) {
    .homeTestimonial__testimonialContent {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .homeTestimonial__content--testimonial {
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
    }
    .homeTestimonial__content--testimonial--left {
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .homeTestimonial__content--testimonial--right {
        width: 100%;    
        padding-left: 2px;
    }

    .homeTestimonial__content--navigation {
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: start;
    }
    .homeTestimonial__content--navigation--next {
        margin-left: 20px;
    }
    .homeTestimonial__content--testimonial--right {
        height: auto;
    }
}
