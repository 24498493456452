.popularProducts {
    max-width: 100vw;
    --slider-padding: 5rem;
    overflow: hidden;
    background-image: url(../../Assets/backgroundPlateImage.png);
    background-repeat: no-repeat;
    background-position: 0% 0%;
    background-size: contain;
    padding-top: var(--padding_top);
    padding-bottom: var(--padding_bottom);
}

.popularProducts__heading {
    width: 100%;
    /* padding-left: 150px;
    padding-right: 150px; */
    text-align: center;
    color: var(--primary_one);
    margin-bottom: 30px;
}

.popularProducts--cards {
    --slider-index: 0;
    --items-per-screen: 4;
   display: flex;
   width: calc(100% - 2* 5rem);
   transform: translateX(calc(var(--slider-index) * -100%));
   transition: transform 250ms ease-in-out;
   /* overflow: auto; */
}

.popularProducts--card {
    /* min-width: 25%; */
    min-width: calc(100% / var(--items-per-screen));
    padding: 2rem;
}


/* progress bar styling */
.popularProducts__scrollbar {
    width: 100%;
    padding-left: var(--padding_left);
    padding-right: var(--padding_right);
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
}

.popularProducts__scrollbar--item {
    background-color: var(--primary_one);
    height: 0.3rem;
    /* margin-left: 2px; */
    width: 20%;
}

.popularProducts__scrollbar--item.active {
    background-color: var(--primary_two);
    height: 0.7rem;
}


/* navigation button styling */
.popularProducts__navigation {
    width: 100%;
    padding-left: var(--padding_left);
    padding-right: var(--padding_right);
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 50px;
}

.popularProducts__navigation--button--left {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    background-color: var(--secondary_two);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
}

.popularProducts__navigation--button--right {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    background-color: #F9D9DA;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
}


.popularProducts__action {
    width: 100%;
    padding-left: var(--padding_left);
    padding-right: var(--padding_left);
    margin-top: 20px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popularProducts__button {
    padding: 15px 25px;
    color: white;
    border: none;
    background-color: var(--primary_two);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

#popularProducts__button--icon {
    font-size: 2.4rem;
    margin-left: 10px;
    font-weight: bold;
}

/* responsive design */
@media only screen and (max-width: 1000px) {
    .popularProducts--cards {
        --items-per-screen: 3;
    }
}

@media only screen and (max-width: 600px) {
    .popularProducts--cards {
        --items-per-screen: 2;
    }
}

@media only screen and (max-width: 400px) {
    .popularProducts--cards {
        --items-per-screen: 1;
        --slider-index: 1;
    }

    .popularProducts {
        background-size: 70%;
        background-position: -80% 30%;
    }
}