.navbar {
    max-width: 100dvw;
    /* height: 20vh; */
    height: var(--nav-height);
    padding-left: var(--padding_left);
    padding-right: var(--padding_right);
    background: var(--white_nav);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: all 0.7s ease;
    z-index: 7;
}

.navbar:hover {
    background-color: var(--secondary_five);
}

#navbar__white {
    background-color: var(--secondary_five);
    border-bottom: 0.5px solid rgb(224, 224, 224);
}


/* Nav left section */
.navbar__left {
    width: 9.9rem;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 5px;
}

.navbar__left--logo {
    width: 100%;
    cursor: pointer;
}

/* Nav center section */
.navbar__middle {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar__middle--items {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.navbar__middle--item {
    width: max-content;
    text-decoration: none;
    list-style: none;
    cursor: pointer;
    color: var(--primary_one);
    position: relative;
    padding: 2px;
    margin: 0px 20px;
    white-space: nowrap;
}

.navbar__middle--item::after {
    content: "";
    background-color: var(--primary_one);
    display: block;
    transition: all 0.4s ease;
    opacity: 0;
    width: 100%;
    height: 2px;
    position: absolute;
}

.navbar__middle--item:hover::after {
    content: "";
    background-color: var(--primary_one);
    opacity: 100%;
    display: block;
    width: 100%;
    height: 2px;
}



/* Nav Right section */
.navbar__right {
    /* width: 26%; */
    width: max-content;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
}

.navbar__right--items {
    display: flex;
    justify-content: end;
    align-items: center;
}

.navbar__right--items {
    list-style: none;
}

.navbar__right--icon {
    width: 2.7rem;
    cursor: pointer;
    margin: 0px 20px;
}

/* burger icon styling */
.navbar__burger {
    width: 30%;
    width: 40px;
    height: 30px;
    cursor: pointer;
    display: none;
}

.navbar__burger--line {
    width: 100%;
    height: 20%;
    background-color: var(--primary_one);
    border-radius: 8px;
}

/* mobile nav open desing */



/* responsive desing */
/* @media only screen and (max-width: 1250px) {
    .navbar__middle--item {
        margin: 0px 15px;
    }
} */

@media only screen and (max-width: 900px) {
    .navbar__middle {
        display: none;
    }

    #navbar__accountIcon {
        display: none;
    }

    .navbar__burger {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: start;
    }

    .navbar__burger--line {
        width: 40px;
        height: 20%;
        background-color: var(--primary_one);
        border-radius: 8px;
    }

    .navbar__left {
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .navbar__left--logo {
        width: 8rem;
    }

    .navbar__right {
        width: 30%;
    }

    /* handleing the opened stules of burger */
    #navMobileOpen:nth-child(2) {
        display: none;
    }

    /* styles for navbar in mobile view */
    #navMobileOpen {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background-color: var(--secondary_five);
        position: fixed;
        width: 100vw;
        left: 0;
        right: 0;
        top: 20vh;
        bottom: 0;
    }

    #navMobileOpen > .navbar__middle--items {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

}

@media only screen and (max-width: 400px) {
    .navbar {
        padding-left: 10px;
        padding-right: 10px;
    }
    .navbar__right--icon {
        margin: 0px 8px;
    }
}