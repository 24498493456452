.heroContainer {
    max-width: 100vw;
    min-height: 100vh;
    background-image: url(../../Assets/Hero_bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-left: var(--padding_left);
    padding-right: var(--padding_right);
}

.heroContainer__text {
    width: 50vw;
    /* height: 90%; */

    margin-top: 13vh;
    margin-left: 0px;
    margin-bottom: 100px;
    padding-bottom: 50px;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start; */
}

.heroContainer--heading {
    font-size: 6.4rem;
    font-weight: bold;
    color: var(--primary_one);
}

.heroContainer--subHeading {
    font-size: 2.4rem;
    margin-top: 40px;
    color: var(--primary_one);
}

.heroContainer--actionButton {
    background-color: var(--primary_two);
    padding: 2.35rem 5.45rem;
    margin-top: 50px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    font-size: 2.4rem;
}

/* .heroContainer--actionButton:hover {
    font-size: 2.5rem;
} */


@media only screen and (max-width: 700px) {
    .heroContainer__text {
        width: 70vw;
    }
}