.categoryExplore_container {
    max-width: 100vw;
    /* height: 100vh; */
    padding-left: var(--padding_left);
    padding-right: var(--padding_right);
    padding-top: var(--padding_top);
    padding-bottom: var(--padding_bottom);
}

.categoryExplore__content {
    width: 100%;
    /* padding-left: 150px;
    padding-right: 150px; */
}

.categoryExplore__heading {
    width: 100%;
    text-align: center;
}

.categoryExplore--section {
    width: 100%;
    /* height: 100px; */
    margin-top: 50px;
    display: flex;
}

.section_search {
    flex: 1;
    /* height: 100%; */
    /* background-color: red; */
}
.section__product {
    flex: 2;
    height: 100%;
    display: flex;
    justify-content: space-between;

}

.categoryExplore__search {
    width: 80%;
    position: relative;
    background-color: red;
}

.section_search--input {
    position: absolute;
    width: 100%;
    /* height: 30px; */
    border-radius: 10px;
    padding: 10px 6px 10px 40px;
    background-color: #F0F0F0;
    color: #6E757E;
    font-size: 2rem;
    border: none;
    outline: none;
}

.categoryExplore__searchIcon {
    position: absolute;
    width: 20px;
    /* left: 10px; */
    left: 4%;
    top: 12px;
}

.section_search--options {
    margin-top: 80px;
}

.section_search--option--item {
    font-size: 2rem;
    margin-bottom: 30px;
    width: 80%;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    cursor: pointer;
    transition: all 0.4s ease;
    border-radius: 4px;
}

.section_search--option--item:hover {
    background-color: var(--secondary_two);
}

.categoryExplore__item {
    width: 47%;
    height: 240px;
    border-radius: 10px;
    background-color: rgb(210, 210, 210);
    cursor: pointer;
    transition: all 0.4s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* transform: all 0.4s ease; */
}

.categoryExplore__item--image {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 10px;
}

.categoryExplore__item__hover {
    display: none;   
    transition: all 0.4s ease;
    position: absolute;
}

.categoryExplore__item__heading {
    color: var(--secondary_five);
    font-size: 6.4rem;
    margin-bottom: 15px;
    transition: all 0.4s ease;
}

.categoryExplore__item__button {
    background-color: var(--secondary_two);
    border: none;
    /* padding: 10px 20px; */
    padding: 14px 43px;
    border-radius: 10px;
    font-size: 1.8rem;
    transition: all 0.4s ease;
}

.categoryExplore__item:hover {
    background-color: #8d8d8d;
    transform: scale(1.08);
}

.categoryExplore__item:hover > .categoryExplore__item__hover {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 0.4s ease;
}


/* responsive desing */
@media only screen and (max-width: 700px) {
    .categoryExplore--section {
        display: flex;
        flex-direction: column;
    }
    .categoryExplore__search {
        margin: auto;
    }
    .section_search--options {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media only screen and (max-width: 480px) {
    .section__product {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .categoryExplore__item {
        width: 80%;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}