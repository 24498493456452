.specialPackage {
    max-width: 100vw;
    padding-left: var(--padding_left);
    padding-right: var(--padding_right);
    padding-top: var(--padding_top);
    padding-bottom: var(--padding_bottom);
}

/* suggestion color cards */
#color_one {
    background-color: var(--secondary_one);
}

#color_two {
    background-color: var(--secondary_two);
}

#color_three {
    background-color: var(--secondary_three);
}

#color_four {
    background-color: var(--secondary_four);
}

.specialPackage__heading {
    width: 100%;
    text-align: center;
}

.specialPackage__content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    /* height: 600px; */
    margin-top: 70px;
}

/* TODO: remove it later */
.specialPackageRatingStar {
    width: 60%;
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.specialPackageRatingStar--star {
    width: 3.9rem;
    margin-right: 10px;
}

/* left content */
.specialPackage__content--left {
    width: 50%;
    height: 100%;
}
.specialPackage__content--left--image {
    width: 100%;
    height: 40rem;
}

.specialPackage__content--left--img {
    width: 100%;
    height: 100%;
}

.specialPackage__content--details {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.specialPackage__content--details-left {
    width: 70%;
}

.specialPackage__content--Cart_button_container {
    width: 30%;
    /* height: 100%; */
    display: flex;
    justify-content: center;
    align-items: start;
}

.specialPackage__content--Cart_button {
    width: 100%;
    padding: 14px 22px;
    border-radius: 8px;
    background-color: var(--primary_two);
    border: none;
    display: flex;
    /* word-wrap: none; */
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
}

#specialPackage__content--Cart_button--icon {
    font-size: 2.4rem;
    margin-left: 10px;
    font-weight: bold;
}

/* right content */
.specialPackage__content--right {
    width: 50%;
    padding-left: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.specialPackage__content--description--listItems {
    margin-left: 3rem;
}

/* .specialPackage__content--right--description {

} */

.specialPackage__content--seeMore {
    width: max-content;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: 10px;
}

/* suggestion section */
.specialPackage__suggestion {
    margin-top: 20px;
    width: 100%;
}
.specialPackage__suggestion--container {
    width: 100%;
    height: 10rem;
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-top: 2rem;
}

.specialPackage__suggestion--left {
    width: 40%;
    height: 100%;
    /* background-color: rgb(184, 184, 184); */
    border-radius: 8px;
}

.specialPackage__suggestion--right {
    padding-left: 2rem;
}

.specialPackage__suggestion--seeMore {
    width: max-content;
    background-color: transparent;
    margin-top: 10px;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
    font-size: 1.6rem;
}

.specialPackage__sggestion--left--img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}


/* responsive desings */
@media only screen and (max-width: 970px) {
    
    .specialPackage__content--right {
        padding-left: 2rem;
    }
}

@media only screen and (max-width: 790px) {
    
    .specialPackage__content {
        display: flex;
        flex-direction: column;
    }
    .specialPackage__content--left {
        width: 80%;
        margin: auto;
        /* height: 100%; */
    }
    .specialPackage__content--right {
        width: 80%;
        margin: 40px auto;
        /* height: 100%; */
    }
    .specialPackage__content--details {
        display: flex;
        flex-direction: column;
        align-items: start;
    }
    .specialPackage__content--Cart_button_container {
        margin-top: 20px;
        justify-content: start;
    }
    
}

@media only screen and (max-width: 790px) {
    .specialPackage__content--right {
        width: 95%;
        margin: 40px auto;
        /* height: 100%; */
    }
    .specialPackage__content--left {
        width: 95%;
        margin: auto;
        /* height: 100%; */
    }
    .specialPackage__content--left--image {
        width: 100%;
    }
    .specialPackage__content--Cart_button_container {
        width: 30%;
    }
}

@media only screen and (max-width: 470px) {
    .specialPackage__content--Cart_button_container {
        width: 80%;
    }
}